.logo {
  width: 3rem;
  height: 3rem;
}

nav {
  background-color: white;
  height: 5rem;
  box-shadow: 3px 5px 15px #cefaf6;
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  margin-left: 1rem;
  height: 2.5rem !important;
}

nav ul {
  font-weight: 500;
  letter-spacing: 0.0357rem;
  cursor: pointer;
  font-size: 20px;
}

nav ul li {
  text-transform: uppercase;
}

.contactME{
  color: #30d5c8;
  border: 1px solid black;
  border-radius: 2.2rem;
  padding: 0.8rem 1rem;
}

.navbar-nav > li > a {
  margin-right: 1rem;
  margin-left: 1rem;
  /* border-top: 0.1875rem solid  white; */
}

.navbar-nav > li > a.active, .navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid black;
  color: #30d5c8;
  text-decoration: none;
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid #30d5c8;
}

.contactME:hover {
  background-color: #30d5c8;
  color: black;
  text-decoration: none;
  border: 0.1875px solid #30d5c8;

}

.contactME:active ,.contactME.active:hover{
  background-color: #30d5c8;
  color: black;
  text-decoration: none;
  border: 0.1875px solid #30d5c8;
}


@media only screen and (min-width: 340px) and (max-width: 760px){	
  .logo {
    width: 2.8rem;
    height: 2.8rem;
  }

  #navbarSupportedContent {
    margin-top: 32px;
  }

  nav {
    opacity: 0.93;
  }

  .navbar-brand {
    margin-left: 1.8rem;
  }

  .navbar-nav > li > a {
    margin: 26px 0;
    display: block;
    text-align: center;
    background-color: white;
  }

  .navbar-nav > li > a.active, .navbar-nav > li > a.active:focus {
    border-top: 0.1rem solid black;
  }

  .navbar-nav > li > a:hover {
    border-top: 0.1rem solid #30d5c8;
  }

  .contactME:hover{
    background-color: #30d5c8;
    color: black;
    text-decoration: none;
    border: 1px solid #30d5c8;
  }
  
  .contactME:active ,.contactME.active:hover {
    background-color: #30d5c8;
    color: black;
    text-decoration: none;
    border: 1px solid #30d5c8;
  }

  .navbar-toggler {
    font-size: larger;
  }

  nav ul {
    font-weight: 400;
    background-color: white;
    font-size: 24px;
  }
}