.contact-container{
  background-color: #f0ffff;
  padding: 2rem 0 2rem;
  display: flex;
  flex-direction: row;
  gap: 16rem;
}

.contact-icon {
  font-size: 300px;
  color: #afeeee;
  margin-left: 18rem;
  margin-top: 4rem;
}

.contact-subscription {
  display: flex;
  flex-direction: column;
  margin: 4rem 0;
  color: black;
  text-align: center;
}

.contact-quote {
  font-family: 'Trebuchet MS', 'Lucinda Sans Unicode', 'Lucinda Grande',
  'Lucinda Sans', Arial, sans-serif;
  color: black;
  font-size: 32px;
}

.contact-subquote {
  font-family: 'Trebuchet MS', 'Lucinda Sans Unicode', 'Lucinda Grande',
  'Lucinda Sans', Arial, sans-serif;
  color: grey;
  margin-bottom: 42px;
  font-size: 18px;
}

.input-areas{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid black;
  width: 340px;
}

.message {
  height: 140px;
}

.contact-input::placeholder {
  color: #b1b1b1;
}

.input-areas .contact-p{
  border-radius: 18px;
  width: 150px;
  background-color: grey;
  font-size: 18px;
  height: 45px;
  border: none;
  box-shadow: 5px 10px 18px #d3d3d3;
  margin-top: 15px;
  padding-top: 9px;
  margin-left: 110px;
  cursor: pointer;
}

/* .input-areas .contact-p:hover{
  background-color: black;
  color:#30d5c8;
} */

.contact-disabled {
  font-size: 14px;
  color: #a7a7a7;
}


@media only screen and (min-width: 240px) and (max-width: 760px){	
  .contact-icon{
    display: none;
  }

  .contact-subscription {
    margin:auto;
  }

  .contact-input {
    margin: auto;
    margin-bottom: 18px;
    width: 220px;
  }

  .message {
    height: 100px;
  }
}
