h4 {
  font-family: 'Trebuchet MS', 'Lucinda Sans Unicode', 'Lucinda Grande',
  'Lucinda Sans', Arial, sans-serif;
  margin-bottom: 1.5rem;
  font-size: 48px;
}

.subContent-Technologies {
  padding: 4.8rem 0 0 0;
  text-align: center;
  margin-bottom: 3rem;
}

.technologies {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  align-items: center;
  justify-content: center;
  margin: 3rem 10rem 0;
}

.technologyImg {
  padding: 0rem;
}

.technologyIcon {
  font-size: 120px;
  padding: 1rem;
}

.fa-html5 {
  color:#f06529;
}

.fa-css3-alt {
  color: #2652e8;
}

.fa-react {
  color: #62dbfb
}

.fa-node {
  color: #8ccc4c;
}

.img-technology {
  width: 220px;
  padding: 0.4rem;
}

.img-technology-right {
  width: 120px;
}

.img-technology-pug {
  width: 140px;
}

@media only screen and (min-width: 240px) and (max-width: 760px){	
  h4 {
    font-size: 32px;
  }

  .technologies {
    margin: 3rem 0rem;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .technologyImg {
    padding: 1rem;
  } 

  .technologyIcon {
    font-size: 100px;
    padding: 0rem;
  }

  .img-technology {
    width: 150px;
  }

  .img-technology-right {
    width: 90px;
  }
  
  .img-technology-pug {
    width: 100px;
  }
}
