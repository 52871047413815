.content {
  padding: 4.8rem 0 0 0;
  text-align: center;
}

.subContent {
  margin-bottom: 3rem;
}

.subContent-Projects {
  background-color:#fdfff5;
  padding: 2rem 0;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.8rem;
}

h4 {
  font-family: 'Trebuchet MS', 'Lucinda Sans Unicode', 'Lucinda Grande',
  'Lucinda Sans', Arial, sans-serif;
  margin-bottom: 1.5rem;
  font-size: 48px;
}

.upperProject {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4.8rem;
}

.projectLogo-Xinney {
  width: 300px;
}

.projectLogo-DrSaab {
  width: 400px;
}

.projectLogo-Deckdo {
  width: 200px;
}

@media only screen and (min-width: 240px) and (max-width: 760px){	
  h4 {
    font-size: 32px;
  }

  .subContent-Projects {
    gap: 3.8rem;
  }
  
  .upperProject {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.8rem;
  }
  
  .projectLogo-Xinney {
    width: 200px;
  }

  .projectLogo-DrSaab {
    width: 300px;
  }

  .projectLogo-Deckdo {
    width: 150px;
  }
}
