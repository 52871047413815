.footer-container{
  background-color: #30d5c8;
  padding: 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 3.8rem auto;
  flex-direction: column;
  gap: 3.2rem;
}

.social-media-wrap p {
  color: 		#4f4f4f;
  font-family: 'Trebuchet MS', 'Lucinda Sans Unicode', 'Lucinda Grande',
  'Lucinda Sans', Arial, sans-serif;
  font-size: 22px;
}

.social-media-wrap img{
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.7rem;
}

.social-icons {
  display:flex;
  justify-content: space-between;
  align-items:center;
  width: 300px;
  font-size: 12px;
}

.social-icon-link {
  color:black;
}

.social-icon-link:hover {
  color: 	whitesmoke;
}

.website-rights {
  color: #5e5e5e;
  text-align:center;
  font-size: 16px;
}

@media only screen and (min-width: 340px) and (max-width: 760px) {
  .social-media-wrap {
    margin: 1rem auto;
    gap: 1.2rem;
  }

  .social-media-wrap img{
    margin-bottom: 0.9rem;
  }

  .social-media-wrap p {
    font-size: 20px;
  }

  .social-icons {
    width: 230px;
    font-size: 10px;
    margin-bottom: 0.8rem;
  }

  .website-rights {
    font-size: 14px;
  }
}


