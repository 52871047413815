.content-aboutme {
  padding: 4.8rem 0 0 0;
  margin: 2rem 8rem 0 0rem;
  text-align: left;
}

#about {
  display: flex;
  flex-direction: row;
}

h6 {
  font-family: 'Trebuchet MS', 'Lucinda Sans Unicode', 'Lucinda Grande',
  'Lucinda Sans', Arial, sans-serif;
  font-size: 24px;
}

p {
  font-family: 'Trebuchet MS', 'Lucinda Sans Unicode', 'Lucinda Grande',
  'Lucinda Sans', Arial, sans-serif;
  margin-bottom: 2rem;
  font-size: 34px;
}

.container-lg > img {
  width: 60%;
  display: block;
  margin: auto;
}

@media only screen and (min-width: 240px) and (max-width: 760px){	
  .content-aboutme {
    padding: 2rem 0 0 0;
    text-align: center;
    margin: 2rem;
    text-align: left;
  }

  #about {
    flex-direction: column;
  }

  h6 {
    font-size: 18px;
    margin: 0rem 0rem 0rem -0.5rem;
  }

  p {
    font-size: 23px;
    margin-left: 0.5rem;
  }

  .container-lg > img {
    width: 70%;
    display: block;
    margin: auto;
  }
}
